<template>
    <div class="order-search">
			<div class="search-condition">
				<div class="condition-time">
					<span>从</span>
					<el-date-picker
					    v-model="startDate"
					    type="date"
					    format="yyyy-MM-dd"
					    value-format="yyyy-MM-dd"
					    placeholder="开始时间"
					    @change="handleStart">
					</el-date-picker>
					<span>到</span>
					<el-date-picker
					    v-model="endDate"
					    type="date"
					    format="yyyy-MM-dd"
					    value-format="yyyy-MM-dd"
					    :picker-options="pickerOptions"
					    placeholder="结束时间"
					    @change="handleEnd">
					</el-date-picker>
				</div>
				<div class="filtrate">
					<slot  name="filtrateStyle"></slot>
					<slot  name="filtrateDistance"></slot>
				</div>
				
			</div>
        <el-button
            type="warning"
            @click="handleSearch"
            class="search-btn">
            查询
        </el-button>
    </div>
</template>

<script>
    export default {
        name: 'orderSearch',
        data() {
            return {
                startDate: '',
                endDate: '',
                pickerOptions: {
                    disabledDate:(time)=>{
                        return time.getTime() < new Date(this.startDate).getTime();
                    }
                }
            }
        },
        props: {
            start: [ String, Date, Object ],
            end: [ String, Date, Object ],
        },
        watch: {
            start: {
                handler: function(value) {
                    this.startDate = value;
                },
                immediate: true
            },
            end: {
                handler: function(value) {
                    this.startDate = value;
                },
                immediate: true
            }
        },
        methods: {
            handleStart(date) {
                this.$emit('handle-start', date);
            },
            handleEnd(date) {
                this.$emit('handle-end', date);
            },
            handleSearch() {
                this.$emit('handle-search');
            }
        }
    }
</script>

<style scoped>
.order-search {
	display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 6px;
    padding: 20px 10px;
}
.order-search > span {
    margin: 0 10px;
}
.search-btn {
    margin-left: 20px;
}
.search-condition{
	display: flex;
	align-items:flex-start;
	flex-direction:column
}
.filtrate{
	display: flex;
}
</style>